import { DuotonePicker, Button, Dropdown } from "@wordpress/components";

export default function DuotoneMenu({ ...props }) {
  return (
    <>
      <Dropdown
        className="my-container-class-name"
        contentClassName="my-dropdown-content-classname"
        popoverProps={{ placement: "bottom-start" }}
        renderToggle={({ isOpen, onToggle }) => (
          <Button variant="primary" onClick={onToggle} aria-expanded={isOpen}>
            Duotone Effect
          </Button>
        )}
        renderContent={() => 
          <div style={{ padding: "8px", width: "300px" }}>
            <DuotonePicker
              label="Duotone Effect"
              labelPosition="top"
              duotonePalette={[
                {
                  colors: ["#0D1B28", "#ffffff"],
                  name: "Blue and Ahitw",
                  slug: "blue-white",
                },
              ]}
              value={props.attributes.imageDuotone}
              onChange={(v) => {
                props.setAttributes({ imageDuotone: v });
                props.setCSS(css);
              }}
            />
          </div>
        }
      />
    </>
  );
}
