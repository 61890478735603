import { InspectorControls } from "@wordpress/block-editor";
import { DuotonePicker } from "@wordpress/components";
import {
  PanelBody,
  QueryControls,
  TextControl,
  SelectControl,
  RangeControl,
  Button,
  ColorPalette,
} from "@wordpress/components";
import { useEffect } from "@wordpress/element";
import DuotoneMenu from "./duotone";

export default function Interface({ setCSS, css, ...props }) {
  const filteredPostTypes = props.postTypes?.filter((item) => {
    return item.viewable;
  });

  const cleanedPostTypes = filteredPostTypes?.map((item) => {
    if (item.viewable === false) return null;

    return {
      label: item.name,
      value: item.slug,
    };
  });

  return (
    <InspectorControls key="setting">
      <PanelBody title="General" initialOpen>
        <div>
          <fieldset>
            <SelectControl
              label="Post Type"
              onChange={(v) => {
                props.setAttributes({ postType: v });
                setCSS(css);
              }}
              options={cleanedPostTypes}
            />
          </fieldset>
          <fieldset>
            <QueryControls
              numberOfItems={props.postsToShow}
              onNumberOfItemsChange={(v) =>
                props.setAttributes({ postsToShow: v })
              }
              selectedCategoryId={props.category}
              onCategoryChange={(v) => props.setAttributes({ category: v })}
            />
          </fieldset>
        </div>
      </PanelBody>
      <PanelBody title="Style">
        <div>
          <fieldset>
            <ColorPalette
              label="Background Color"
              value={props.attributes.imageBgColor}
              onChange={(v) => {
                props.setAttributes({ imageBgColor: v });
                setCSS(css);
              }}
            />
          </fieldset>
          <fieldset>
            <SelectControl
              label="Aspect Ratio"
              onChange={(v) => {
                props.setAttributes({ aspectRatio: v });
                setCSS(css);
              }}
              options={[
                {
                  label: "Undefined",
                  value: "",
                },
                {
                  label: "1:1",
                  value: "1/1",
                },
                {
                  disabled: true,
                  label: "Vertical",
                  value: "",
                },
                {
                  label: "2:3",
                  value: "2/3",
                },
                {
                  label: "1:2",
                  value: "1/2",
                },
                {
                  label: "9:16",
                  value: "9/16",
                },
                {
                  label: "1:3",
                  value: "1/3",
                },
                {
                  disabled: true,
                  label: "Horizontal",
                  value: "",
                },
                {
                  label: "3:2",
                  value: "3/2",
                },
                {
                  label: "2:1",
                  value: "2/1",
                },
                {
                  label: "16:9",
                  value: "16/9",
                },
                {
                  label: "3:1",
                  value: "3/1",
                },
              ]}
            />
          </fieldset>
          <fieldset>
            <DuotoneMenu {...props} setCSS={setCSS} />
          </fieldset>
          <fieldset>
            <SelectControl
              label="Image Size"
              onChange={(v) => {
                props.setAttributes({ imageSize: v });
                setCSS(css);
              }}
              options={[
                {
                  label: "Full Size",
                  value: "full",
                },
                {
                  label: "Large",
                  value: "large",
                },
                {
                  label: "Medium Large",
                  value: "medium_large",
                },
                {
                  label: "Medium",
                  value: "medium",
                },
                {
                  label: "Thumbnail",
                  value: "thumbnail",
                },
              ]}
            />
          </fieldset>
          <fieldset>
            <TextControl
              label="Border Radius"
              onChange={(v) => {
                props.setAttributes({ borderRadius: Number(v) });
                setCSS(css);
              }}
              type="number"
              value={props.attributes.borderRadius}
            />
          </fieldset>
          <fieldset>
            <Button onClick={() => setForceRefetch(true)} variant="primary">
              Refetch Images
            </Button>
          </fieldset>
        </div>
      </PanelBody>
      <PanelBody title="Grid (default)" initialOpen>
        <div>
          <fieldset>
            <RangeControl
              label="Columns"
              step={1}
              separatorType="fullWidth"
              isShiftStepEnabled={true}
              separatorTypes={"none"}
              type="stepper"
              value={props.attributes.gridColumns}
              onChange={(v) => {
                props.setAttributes({ gridColumns: Number(v) });
                setCSS(css);
              }}
              min={1}
              max={12}
              withInputField={true}
            />
          </fieldset>
          <fieldset style={{ display: "flex", gap: "1rem" }}>
            <TextControl
              label="Horizontal Gap"
              onChange={(v) => {
                props.setAttributes({ gridGapH: Number(v) });
                setCSS(css);
              }}
              type="number"
              value={props.attributes.gridGapH}
            />
            <TextControl
              label="Vertical Gap"
              onChange={(v) => {
                props.setAttributes({ gridGapV: Number(v) });
                setCSS(css);
              }}
              type="number"
              value={props.attributes.gridGapV}
            />
          </fieldset>
        </div>
      </PanelBody>
      <PanelBody title="Grid (tablet)" initialOpen={false}>
        <div>
          <fieldset>
            <RangeControl
              label="Columns"
              step={1}
              separatorType="fullWidth"
              isShiftStepEnabled={true}
              separatorTypes={"none"}
              type="stepper"
              value={props.attributes.tab_gridColumns}
              onChange={(v) => {
                props.setAttributes({ tab_gridColumns: Number(v) });
                setCSS(css);
              }}
              min={1}
              max={12}
              withInputField={true}
            />
          </fieldset>
          <fieldset style={{ display: "flex", gap: "1rem" }}>
            <TextControl
              label="Horizontal Gap"
              onChange={(v) => {
                props.setAttributes({ tab_gridGapH: Number(v) });
                setCSS(css);
              }}
              type="number"
              value={props.attributes.tab_gridGapH}
            />
            <TextControl
              label="Vertical Gap"
              onChange={(v) => {
                props.setAttributes({ tab_gridGapV: Number(v) });
                setCSS(css);
              }}
              type="number"
              value={props.attributes.tab_gridGapV}
            />
          </fieldset>
        </div>
      </PanelBody>
      <PanelBody title="Grid (mobile)" initialOpen={false}>
        <div>
          <fieldset>
            <RangeControl
              label="Columns"
              step={1}
              separatorType="fullWidth"
              isShiftStepEnabled={true}
              separatorTypes={"none"}
              type="stepper"
              value={props.attributes.mob_gridColumns}
              onChange={(v) => {
                props.setAttributes({ mob_gridColumns: Number(v) });
                setCSS(css);
              }}
              min={1}
              max={12}
              withInputField={true}
            />
          </fieldset>
          <fieldset style={{ display: "flex", gap: "1rem" }}>
            <TextControl
              label="Horizontal Gap"
              onChange={(v) => {
                props.setAttributes({ mob_gridGapH: Number(v) });
                setCSS(css);
              }}
              type="number"
              value={props.attributes.mob_gridGapH}
            />
            <TextControl
              label="Vertical Gap"
              onChange={(v) => {
                props.setAttributes({ mob_gridGapV: Number(v) });
                setCSS(css);
              }}
              type="number"
              value={props.attributes.mob_gridGapV}
            />
          </fieldset>
        </div>
      </PanelBody>
    </InspectorControls>
  );
}
