import { useBlockProps } from "@wordpress/block-editor";
import { useSelect } from "@wordpress/data";
import { useEffect } from "@wordpress/element";
import Interface from "./interface";

const Edit = ({ ...props }) => {
  const { postsToShow, postType, category } = props.attributes;
  const blockProps = useBlockProps();

  const setCSS = (css) => {
    props.setAttributes({ css: css });
  };

  // Fetch post types
  const postTypes = useSelect(
    (select) => select("core").getPostTypes({ per_page: -1 }),
    [postType, postsToShow, category]
  );

  const posts = useSelect(
    (select) =>
      select("core").getEntityRecords("postType", postType, {
        per_page: postsToShow,
        categories: category ? [category] : undefined,
        _embed: true,
      }),
    [postType, postsToShow, category]
  );

  console.log("posts", props.attributes);

  useEffect(() => {
    props.setAttributes({ posts: posts });
  }, [posts]);

  const css = `
      .ca-related-grid{
        display: grid;
        row-gap: ${props.attributes.gridGapV}px;
        column-gap: ${props.attributes.gridGapH}px;
        grid-template-columns: repeat(${props.attributes.gridColumns}, 1fr);
      }

      .ca-related-grid-item{
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .ca-related-grid-item-thumbnail{
        display: flex;
        width: 100%; 
        overflow: hidden;
        border-radius: ${props.attributes.borderRadius}px;
      }
  
      .ca-related-grid-item-content{
        display: flex;
        flex-direction: column;
      }

      .ca-related-grid-item-title{
        margin: 0;
      }

      .ca-related-grid-item-date{
        text-transform: uppercase;
      }

      .ca-related-grid-item-image{
        background: ${props.attributes.imageBgColor};
        border: none;
        width: 100%; 
        height: 100%;
        object-fit: cover;
        ${
          props.attributes.aspectRatio !== ""
            ? `aspect-ratio: ${props.attributes.aspectRatio};`
            : ""
        }
      }

      @media (max-width: 768px) {
        .ca-related-grid{
          row-gap: ${props.attributes.mob_gridGapV}px;
          column-gap: ${props.attributes.mob_gridGapH}px;
          grid-template-columns: repeat(${
            props.attributes.mob_gridColumns
          }, 1fr);
        }
      }

      @media (min-width: 769px) and (max-width: 1080) {
        .ca-related-grid{
          row-gap: ${props.attributes.tab_gridGapV}px;
          column-gap: ${props.attributes.tab_gridGapH}px;
          grid-template-columns: repeat(${
            props.attributes.tab_gridColumns
          }, 1fr);
        }
      }

      .ca-duotone-thumbnail {
        position: relative;
      }

      .ca-duotone-thumbnail img {
        filter: grayscale(1);
      }

      .ca-duotone-light {
        background-color: ${props.attributes.imageDuotone[1]};
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        mix-blend-mode: darken;
        position: absolute;
        top: 0;
        left: 0;
      }

      .ca-duotone-dark {
        background-color: ${props.attributes.imageDuotone[0]};
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        mix-blend-mode: plus-lighter;
        position: absolute;
        top: 0;
        left: 0;
      }
  `;

  return (
    <div {...blockProps}>
      <Interface
        {...props}
        setCSS={setCSS}
        css={css}
        postsToShow={postsToShow}
        category={category}
        postType={postType}
        postTypes={postTypes}
      />
      <style type="text/css">{css}</style>
      <div className="ca-related-grid">
        {posts
          ? posts?.map((post, i) => (
              <div class="ca-related-grid-item" key={i}>
                <figure class="ca-related-grid-item-thumbnail ca-duotone-thumbnail">
                    <img
                      class="ca-related-grid-item-image"
                      src={post._embedded["wp:featuredmedia"]?.[0].source_url}
                    />
                    <div class="ca-duotone-dark"></div>
                    <div class="ca-duotone-light"></div>
                </figure>
                <span>{post.title.rendered}</span>
              </div>
            ))
          : () => {
              for (let i = 0; i < postsToShow; i++) {
                return (
                  <div class="ca-related-grid-item">
                    <div class="ca-related-grid-item-thumbnail">
                      <div class="ca-related-grid-item-image"></div>
                    </div>
                    <div>Title</div>
                  </div>
                );
              }
            }}
      </div>
    </div>
  );
};

export default Edit;
