import { registerBlockType } from "@wordpress/blocks";
import "./style.css";

import Edit from "./edit";
import save from "./save";
import metadata from "./block.json";

registerBlockType(metadata.name, {
  title: metadata.title,
  icon: (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      aria-hidden="true"
      focusable="false"
    >
      <path
        fill="red"
        d="M16.375 4.5H4.625a.125.125 0 0 0-.125.125v8.254l2.859-1.54a.75.75 0 0 1 .68-.016l2.384 1.142 2.89-2.074a.75.75 0 0 1 .874 0l2.313 1.66V4.625a.125.125 0 0 0-.125-.125Zm.125 9.398-2.75-1.975-2.813 2.02a.75.75 0 0 1-.76.067l-2.444-1.17L4.5 14.583v1.792c0 .069.056.125.125.125h11.75a.125.125 0 0 0 .125-.125v-2.477ZM4.625 3C3.728 3 3 3.728 3 4.625v11.75C3 17.273 3.728 18 4.625 18h11.75c.898 0 1.625-.727 1.625-1.625V4.625C18 3.728 17.273 3 16.375 3H4.625ZM20 8v11c0 .69-.31 1-.999 1H6v1.5h13.001c1.52 0 2.499-.982 2.499-2.5V8H20Z"
        fill-rule="evenodd"
        clip-rule="evenodd"
      ></path>
    </svg>
  ),
  category: metadata.category,
  attributes: {
    imageDuotone: {
      type: "array",
      default: ['#000', '#fff']
    },
    imageBgColor: {
      type: "string",
      default: "#00000011",
    },
    posts: {
      type: "array",
      default: [],
    },
    postTitle: {
      type: "string",
      default: "demo",
    },
    postsToShow: {
      type: "number",
      default: 5,
    },
    postType: {
      type: "string",
      default: "post",
    },
    postId: {
      type: "number",
      default: 0,
    },
    category: {
      type: "number",
      default: 0,
    },
    imageSize: {
      type: "string",
      default: "full",
    },
    costumField: {
      type: "string",
      default: null,
    },
    postType: {
      type: "string",
      default: undefined,
    },
    gridColumns: {
      type: "number",
      default: 3,
    },
    gridGapV: {
      type: "number",
      default: 16,
    },
    borderRadius: {
      type: "number",
      default: 0,
    },
    gridGapH: {
      type: "number",
      default: 16,
    },
    tab_gridColumns: {
      type: "number",
      default: 3,
    },
    tab_gridGapV: {
      type: "number",
      default: 16,
    },
    tab_gridGapH: {
      type: "number",
      default: 16,
    },
    mob_gridColumns: {
      type: "number",
      default: 3,
    },
    mob_gridGapV: {
      type: "number",
      default: 16,
    },
    mob_gridGapH: {
      type: "number",
      default: 16,
    },
    items: {
      type: "array",
      default: [],
    },
    aspectRatio: {
      type: "string",
      default: "",
    },
    css: {
      type: "string",
      default: "",
    },
  },
  edit: Edit,
  save,
});
